<script>
import LoggerStatusIcon from '@/components/LoggerStatusIcon.vue';

export default {
  name: 'LoggerSelectPageList',
  components: {
    LoggerStatusIcon,
  },
  data() {
    return {
      itemsPerPage: 10,
      currentPage: 1,
      fields: [
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'serial',
          label: 'Serial',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          formatter: name => name || '(Not Set)',
        },
        {
          key: 'description',
          label: 'Description',
          sortable: true,
          formatter: desc => desc || '(Not Set)',
        },
        {
          key: 'last_update',
          label: 'Last Update',
          sortable: true,
          formatter: date => this.$d(date, 'short'),
        },
        {
          key: 'id',
          label: '',
        },
      ],
    };
  },
  computed: {
    totalLoggers() {
      return this.$store.getters['loggers/listLength'];
    },
  },
};
</script>

<template>
  <b-card>
      <b-row>
        <b-col>
          <b-table id = "loggerList"
              :items="$store.getters['loggers/list']"
              :fields='fields'
              :current-page='currentPage'
              :per-page='itemsPerPage'
              sort-icon-left
              responsive>

            <template v-slot:cell(status)="data">
              <logger-status-icon :status='data.item.status' :alarm='data.item.alarm' />
            </template>

            <template v-slot:cell(id)="data">
              <b-btn variant='primary' @click="$emit('view-logger', data.item.id)">View</b-btn>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <!-- Footer of Logger List -->
      <template v-slot:footer>
        <b-container fluid>
          <b-row align-h='center' no-gutters>
            <b-col cols='auto'>
              <!-- Table Page Selector -->
              <b-pagination
                align="center"
                v-model="currentPage"
                :total-rows= "totalLoggers"
                :per-page="itemsPerPage">
              </b-pagination>
            </b-col>
          </b-row>
        </b-container>
      </template>
  </b-card>
</template>
